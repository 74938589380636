import {
    FETCH_CARS,
    FETCH_CARS_SUCCESS,
    FETCH_CARS_FAILED,
    EDIT_CAR
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  
  export const fetchCars = () => (dispatch) => {
  
    const {
        carsRef
    } = firebase;
  
    dispatch({
      type: FETCH_CARS,
      payload: null
    });

    const userInfo = store.getState().auth.profile;

    carsRef(userInfo.uid, userInfo.usertype).on("value", snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_CARS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_CARS_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_cars
        });
      }
    });
  };
  
  export const editCar = (car, method) => async (dispatch) => {
    const {
      singleUserRef,
      carAddRef, 
      carEditRef,
      carImage
    } = firebase;
    dispatch({
      type: EDIT_CAR,
      payload: { method, car }
    });
    if (method === 'Add') {
        carAddRef.push(car);
    } else if (method === 'Delete') {
        carEditRef(car.id).remove();
    } else if (method === 'UpdateImage') {
      await carImage(car.id).put(car.car_image);
      let image = await carImage(car.id).getDownloadURL();
      let data = car;
      data.car_image = image;
      carEditRef(car.id).set(data);
      if(car.active && car.driver){
        singleUserRef(car.driver).update({
          updateAt: new Date().getTime(),
          car_image: image
        });
      }   
    }
     else {
        carEditRef(car.id).set(car);
    }
  }

  export const updateUserCarWithImage = (newData, blob, blobBack, blobLeft, blobRight, blobLicense, blobLicenseBack, blobRoadTax, blobInsurance) => async (dispatch) => {
    const {
      auth,
      carAddRef,
      singleUserRef,
      carImage,
      carImageBack,
      carImageLeft,
      carImageRight,
      carLicense,
      carLicenseBack,
      carRoadTax,
      carInsurance
    } = firebase;

    var carId = carAddRef.push().key;

    await carImage(carId).put(blob);
    blob.close()
    newData.car_image = await carImage(carId).getDownloadURL();

    await carImageBack(carId).put(blobBack);
    blobBack.close()
    newData.car_imageBack = await carImageBack(carId).getDownloadURL();

    await carImageLeft(carId).put(blobLeft);
    blobLeft.close()
    newData.car_imageLeft = await carImageLeft(carId).getDownloadURL();

    await carImageRight(carId).put(blobRight);
    blobRight.close()
    newData.car_imageRight = await carImageRight(carId).getDownloadURL();

    await carLicense(carId).put(blobLicense);
    blobLicense.close()
    newData.car_license = await carLicense(carId).getDownloadURL();

    await carLicenseBack(carId).put(blobLicenseBack);
    blobLicenseBack.close()
    newData.car_licenseBack = await carLicenseBack(carId).getDownloadURL();

    await carRoadTax(carId).put(blobRoadTax);
    blobRoadTax.close()
    newData.car_roadTax = await carRoadTax(carId).getDownloadURL();

    await carInsurance(carId).put(blobInsurance);
    blobInsurance.close()
    newData.car_insurance = await carInsurance(carId).getDownloadURL();

    carAddRef.child(carId).set(newData);
    if(newData.active){
      let updateData = {
        carType: newData.carType,
        vehicleNumber: newData.vehicleNumber,
        vehicleMake: newData.vehicleMake,
        vehicleModel: newData.vehicleModel,
        other_info: newData.other_info,
        car_image: newData.car_image,
        car_imageBack: newData.car_imageBack,
        car_imageLeft: newData.car_imageLeft,
        car_imageRight: newData.car_imageRight,
        car_license: newData.car_license,
        car_licenseBack: newData.car_licenseBack,
        car_roadTax: newData.car_roadTax,
        car_insurance: newData.car_insurance,
        carApproved: newData.approved,
        updateAt: new Date().getTime()
      };
      singleUserRef(auth.currentUser.uid).update(updateData);
    }
  };