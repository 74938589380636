export const FirebaseConfig = {
	"projectId": "movemate-my",
	"appId": "1:954432441248:web:dcd2125d761933af59b2c5",
	"databaseURL": "https://movemate-my-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "movemate-my.appspot.com",
	"locationId": "asia-southeast2",
	"apiKey": "AIzaSyDMLkWwOriU1ajAyCx7NvIA77xwECQXm24",
	"authDomain": "movemate-my.firebaseapp.com",
	"messagingSenderId": "954432441248",
	"measurementId": "G-KW5Z0ZY8V2"
};